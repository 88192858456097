import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { ApiCheckResponse, ApiSpecResponse, AggregatedRules, DefinedRules } from './types';
import { Table as SummaryTable } from './Summary';
import { Table as IssuesTable, columns as issuesColumns } from './Issues';
import jsonpointer from 'jsonpointer';
import { Badge, Box, Container, Flex, Heading, Separator } from '@chakra-ui/react';
import { Provider } from './components/ui/provider';
import { Table as ObservationTable } from './Observation';
import Header from './Header';
import Footer from './Footer';
import JsonUploader from './JsonUploader';
import Loading from './Loading';
import Error from './Error';
import PassOrFail from './PassOrFail';
import Synopsis from './Synopsis';

const base_url = process.env.NODE_ENV === 'development' ? "http://127.0.0.1:8000" : "";

const resolveJsonPointer = (jsonPointer: string, jsonObject: any) => {
  try {
    if (!jsonObject || !jsonPointer) return '';
    return jsonpointer.get(jsonObject, jsonPointer);
  } catch (error) {
    console.error('Error resolving JSON pointer:', error);
    return '';
  }
};

const extractDefinedRules = (data: ApiSpecResponse): [DefinedRules, string[]] => {
  const rules: DefinedRules = {};
  const errorRules: string[] = [];
  Object.entries(data.rules).forEach(([category, ruleSet]) => {
    Object.entries(ruleSet).forEach(([ruleId, definition]) => {
      rules[ruleId] = definition;
      if (definition[0] === "error") {
        errorRules.push(ruleId);
      }
    });
  });
  return [rules, errorRules];
};

const issueKind = (rule: string, rules: DefinedRules | null | undefined) => {
  if (!rule || !rules) return null;
  return rules[rule][0];
};

function App() {
  return (
    <Provider>
      <OMCChecker />
    </Provider>
  )
}

const mapIssueKind = (kind: string | null, selectedRule: string | null, issuesCount: number): [string, string] => {
  if (!kind || !selectedRule) return ["", "gray"];
  if (kind === "observation") {
    if (selectedRule === "IDSV3") {
      return ["Number of Identifiers Per Scope", "gray"];
    } else {
      return ["", "gray"];
    }
  }
  else if (kind === "error") {
    return [`${issuesCount !== 1 ? `${issuesCount} Errors Found` : "1 Error Found"}`, "red"];
  }
  else if (kind === "warning5") {
    return [`${issuesCount !== 1 ? `${issuesCount} Issues Need Your Attention` : "1 Issue Needs Your Attention"}`, "orange"];
  }
  else if (kind === "warning1") {
    return [`${issuesCount !== 1 ? `${issuesCount} Issues Conditionally Passed` : "1 Issue Conditionally Passed"}`, "yellow"];
  }
  return ["", "gray"];
};

const OMCChecker: React.FC = () => {
  const [spec, setSpec] = useState<ApiSpecResponse | null>(null);
  const [rules, setRules] = useState<DefinedRules | null>(null);
  const [errorRules, setErrorRules] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState<ApiCheckResponse | null>(null);
  const [error, setError] = useState<{error: string, message: string} | null>(null);
  const [selectedRule, setSelectedRule] = useState<string | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const resultsRef = useRef<HTMLDivElement | null>(null);
  const [dataState, setDataState] = useState<'none' | 'loading' | 'error' | 'loaded'>('none');
  const [specState, setSpecState] = useState<'loading' | 'error' | 'loaded'>('loading');

  const readFile = React.useCallback(async () => {
    if (!file) return;
    const text = await file.text();
    if (data?.summary?.WF1 === 'passed') {
      try {
        return JSON.parse(text);
      } catch (error) {
        return null;
      }
    }
    return null;
  }, [data, file]);

  const handleFileUpload = useCallback(async () => {
    if (!file) return;
    setSelectedRule(null);
    setError(null);
    setData(null);
    setDataState('loading');
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axios.post<ApiCheckResponse>(
        `${base_url}/api/check`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Requested-By": "UI",
          },
        }
      );
      setData(response.data);
      setDataState('loaded');
    } catch (error) {
      console.error('Received unexpected error:', error);
      const errorObject = {error: 'Unable to process file', message: 'If the problem persists, please contact MovieLabs.'};
      if (axios.isAxiosError(error)) {
        const errorCode = error.response?.status ? `Error code ${error.response?.status}. ` : '';
        if (
          // error.response?.headers['Content-Type'] === 'application/json' && 
          error.response?.data?.error && 
          error.response?.data?.message
        ) {
          errorObject.error = `${errorCode}${error.response?.data?.error}`;
          errorObject.message = error.response?.data?.message;
        } else {
          errorObject.error = `${errorCode}Unable to process file`;
        }
      }
      setError(errorObject);
      setDataState('error');
    }
  }, [file]);

  useEffect(() => {
    if (file) {
      handleFileUpload();
    }
  }, [file, handleFileUpload]);

  useEffect(() => {
    readFile().then(content => setFileContent(content));
  }, [readFile]);

  useEffect(() => {
    const fetchSpec = async () => {
      try {
        const response = await axios.get<ApiSpecResponse>(
          `${base_url}/api/spec`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-By": "UI",
            },
          }
        );
        setSpec(response.data);
        const [rules, errorRules] = extractDefinedRules(response.data);
        setRules(rules);
        setErrorRules(errorRules);
        setSpecState('loaded');
      } catch (error) {
        console.error('Error fetching spec:', error);
        setSpecState('error');
      }
    };
    fetchSpec();
  }, []);

  const scrollToElement = (element: HTMLElement) => {
    const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const duration = 1000; // ms
    let start: number | null = null;

    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const percent = Math.min(progress / duration, 1);
      window.scrollTo(0, startPosition + distance * percent);
      if (progress < duration) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  const handleRowClick = (rule: string) => {
    setSelectedRule(rule);
  };

  useEffect(() => {
    if (selectedRule && resultsRef.current) {
      scrollToElement(resultsRef.current);
    }
  }, [selectedRule]);

  const issuesForSelectedRule = React.useMemo(() => {
    if (!data || !selectedRule) return [];
    const issues = data.details.issues[selectedRule] || [];
    return issues.map(result => {
      const jsonPointerType = result.context.type;
      const jsonPointers = result.context.jsonPointers.join(', ');
      const jsonPointer = result.context.jsonPointers.length === 1
        ? result.context.jsonPointers[0]
        : null;
      const specifics = jsonPointerType !== 'document' && jsonPointer && !result.specifics
        ? JSON.stringify(resolveJsonPointer(jsonPointer, fileContent), null, 2)
        : result.specifics;

      return {
        issue: result.issue,
        exception: result.exception,
        specifics: specifics,
        jsonPointerTypes: jsonPointerType,
        jsonPointers: jsonPointers,
      };
    });
  }, [data, selectedRule, fileContent]);

  const kind = selectedRule ? issueKind(selectedRule, rules) : null;
  const [issueKindText, issueKindColor] = mapIssueKind(kind, selectedRule, issuesForSelectedRule?.length || 0);
  const hasErrors = data?.summary && Object.entries(data.summary).some(([rule, status]) => errorRules.includes(rule) && status === "failed");
  const hasWarnings = data?.summary && Object.entries(data.summary).some(([rule, status]) => !errorRules.includes(rule) && (status === "failed"));

  return (
    <Flex direction="column" minHeight="100vh">
      <Header />
      
      <Box as="main" flex="1">

        <div style={{ width: '80%', margin: '0% 5% 2% 5%'}}>
          <Container>
            <Synopsis schemaId={spec?.schema.id} schemaVersion={spec?.schema.version} />
          </Container>
        </div>

        <div style={{ width: '80%', margin: '0% 5% 2% 5%'}}>
          <Container>
            <Heading size="2xl" padding="20px 0 20px 0">OMC JSON</Heading>
            <JsonUploader setFile={setFile} specState={specState} />
          </Container>
        </div>

        <Separator />
        
        {(dataState !== 'none' || data) && (
          <div style={{ width: '80%', margin: '0% 5% 2% 5%'}}>
            <Container>
              <Heading size="2xl" padding="20px 0 20px 0">Validation Results</Heading>
            </Container>
            {dataState === 'loading' && <Loading />}
            {error && <Error error={error.error} message={error.message} />}
            {data && <PassOrFail hasErrors={Boolean(hasErrors)} hasWarnings={Boolean(hasWarnings)} data={data} />}
            {data && (
            <Container>
              <Heading size="xl" padding="10px 0 10px 0">Summary</Heading>
              <SummaryTable data={data?.summary} rules={spec?.rules as AggregatedRules} onRowClick={handleRowClick}/>
              <div style={{ paddingBottom: '40px' }} />
            </Container>)}
            {data && selectedRule && (
              <Container ref={resultsRef}>
                <Heading size="xl" padding="10px 0 10px 0">Rule {selectedRule} Results</Heading>
                <div style={{ paddingBottom: '20px' }} />
                <Heading size="md"><Badge colorPalette={issueKindColor}>{issueKindText}</Badge></Heading>
                <div style={{ paddingBottom: '20px' }} />
                {kind !== "observation" && <IssuesTable columns={issuesColumns} data={issuesForSelectedRule}/>}
                {kind === "observation" && <ObservationTable data={data.details.observations[selectedRule]} />}
                <div style={{ paddingBottom: '40px' }} />
              </Container> 
            )}
          </div>
          )}
      </Box>

      <Footer version={spec?.application.version} />
    </Flex>
  );
};

export default App;
