import React from 'react';
import { Box, Center, Text, Link, Separator } from '@chakra-ui/react';

interface FooterProps {
  version?: string;
}

const Footer: React.FC<FooterProps> = ({ version }) => (
  <>
    <Separator />
    <Box as="footer" padding="20px">
      <Center color="gray.500">
      <Text fontSize="sm">© {new Date().getFullYear()} MovieLabs</Text>
      <Text>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Text>
      {version && <Text fontSize="sm">OMC Validator v{version}</Text>}
      {!version && <Text fontSize="sm">OMC Validator</Text>}
      <Text>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Text>
      <Link target="_blank" href="https://mc.movielabs.com/docs/ontology/validator/" fontSize="sm">Manual</Link>
      <Text>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Text>
      <Link target="_blank" href="https://movielabs.com/terms-of-use/" fontSize="sm">Terms of Use</Link>
      <Text>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Text>
      <Link target="_blank" href="https://movielabs.com/privacy-policy/" fontSize="sm">Privacy Policy</Link>
      </Center>
    </Box>
  </>
);

export default Footer; 