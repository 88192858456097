import React, { useCallback } from 'react';
import { FileUploadRoot, FileUploadDropzone, FileUploadList } from './components/ui/file-button';
import { Button } from './components/ui/button';
import { HiUpload } from 'react-icons/hi';
import { Alert } from './components/ui/alert';

interface JsonUploaderProps {
  setFile: (file: File | null) => void;
  specState: 'loading' | 'error' | 'loaded';
}

const JsonUploader: React.FC<JsonUploaderProps> = ({ setFile, specState }) => {
  const handleFileSelect = useCallback((details: { files: File[] }) => {
    const file = details.files[0];
    setFile(file);
  }, [setFile]);

  return (
    <div>
      <FileUploadRoot maxH="sm" alignItems="stretch" accept=".json" onFileAccept={handleFileSelect} maxFiles={1}>
        <FileUploadDropzone label="Drag or select a file" description="Upload a *.json file to validate against the OMC schema.">
          <Button disabled={specState !== 'loaded'}>
          <HiUpload /> Upload file
        </Button>
      </FileUploadDropzone>
      {/* <FileUploadList showSize clearable /> */}
      <FileUploadList />
    </FileUploadRoot>
    {specState === 'error' && (
      <>
        <div style={{ paddingBottom: '20px' }} />
        <Alert status="error" title="Unable to connect to the server">
          Please try again later. If the problem persists, please contact MovieLabs.
        </Alert>
      </>
    )}
    </div>
  );
}

export default JsonUploader; 