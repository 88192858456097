import React from 'react';
import { Alert } from './components/ui/alert';

// Define the props type
interface ErrorProps {
  error: string;
  message: string;
}

// Use the defined props type with React.FC
const Error: React.FC<ErrorProps> = ({ error, message }) => (
  <>
    <div style={{ paddingBottom: '20px' }} />
    <Alert status="error" title={error}>
      {message}
    </Alert>
  </>
);

export default Error; 