import React from 'react';
import { Text, Link } from '@chakra-ui/react';

interface HeaderProps {
  schemaId?: string;
  schemaVersion?: string;
}

const link_color = "rgb(27, 121, 186)";

const Synopsis: React.FC<HeaderProps> = ({ schemaId, schemaVersion }) => (
  <Text fontSize="md" padding="20px 0 0 0">
    This is a validator for the <Link color={link_color} target="_blank" href="https://movielabs.com/production-technology/ontology-for-media-creation/">MovieLabs Ontology for Media Creation</Link> that checks compliance to the <Link href={schemaId} target="_blank" color={link_color}>OMC-JSON Schema v{schemaVersion}</Link> and best practices. Refer to the <Link color={link_color} target="_blank" href="https://mc.movielabs.com/docs/ontology/validator/">manual</Link> for more information.
  </Text>
);

export default Synopsis; 